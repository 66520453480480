import '@/css/app.css'
import Alpine from 'alpinejs'
import Headroom from 'headroom.js'
import collapse from '@alpinejs/collapse'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// import Lenis from 'lenis'

// const lenis = new Lenis({
//     duration: 2
// })

// lenis.on('scroll', (e) => {
//     console.log(e)
// })

// function raf(time) {
//     lenis.raf(time)
//     requestAnimationFrame(raf)
// }

// requestAnimationFrame(raf)

const banner = () => {
    Alpine.data('banner', () => ({
        init() {
            const parent = document.querySelector('.banner')

            const content = parent.querySelector('.js-content')
            // console.log(content)
            setTimeout(() => {
                content.classList.remove('opacity-0')
            }, 150)
            const domainButtons = parent.querySelectorAll('.js-goToDomain')
            const spaceHeight = parent.querySelector('.js-spaceHeight')
            const spacerHeightEl = parent.querySelector('.js-spacer')
            const bgOverlay = parent.querySelector('.js-bgOverlay')
            const textMedia = parent.querySelector('.js-textMedia')
            if (spacerHeightEl.classList.contains('dynamic')) {
                const dynamicSpacerHeight =
                    window.innerHeight - spaceHeight.offsetHeight
                spacerHeightEl.style.height = `${dynamicSpacerHeight}px`
            }

            // console.log(dynamicSpacerHeight)
            const contentHeight =
                parent.querySelector('.js-content').offsetHeight

            const spacerHeight = parent.querySelector('.js-spacer').offsetHeight

            const combinedHeight = contentHeight + spacerHeight
            let extraHeight = combinedHeight - window.innerHeight

            const domains = gsap.utils.toArray('.js-domains', parent)

            const calculateExtraHeight = (combinedHeight) => {
                let extraHeight = combinedHeight - window.innerHeight
                const domains = gsap.utils.toArray('.js-domains', parent)
                if (domains.length > 0 && window.innerWidth > 1024) {
                    extraHeight = extraHeight + domains.length * 500
                }

                return extraHeight
            }
            if (combinedHeight > window.innerHeight) {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: parent,
                        start: () => `top top`,
                        end: () => `+=${calculateExtraHeight(combinedHeight)}`,
                        // markers: true,
                        pin: parent,
                        scrub: true,
                        toggleActions: 'play none reverse none'
                    }
                })
                const tlBg = gsap.timeline({
                    scrollTrigger: {
                        trigger: textMedia,
                        start: () => `top bottom`,
                        end: () => `+=${textMedia.offsetHeight}`,
                        // markers: true,
                        scrub: true,
                        toggleActions: 'play none reverse none'
                    }
                })

                tlBg.to(bgOverlay, {
                    height: '100%'
                })

                tl.to(content, {
                    y: () => `-${extraHeight}px`
                })

                if (domains.length > 0) {
                    domains.forEach((domain, index) => {
                        tl.to(domain, {
                            onStart: () => {
                                if (index == 0) {
                                    this.showDomainBackgrounds = true
                                }
                            },
                            onReverseComplete: () => {
                                if (index == 0) {
                                    this.showDomainBackgrounds = false
                                }
                                if (this.activeIndex > 1) {
                                    this.activeIndex = this.activeIndex - 1
                                    // console.log(
                                    //     'onReverseComplete,',
                                    //     this.activeIndex
                                    // )
                                }
                            },
                            onComplete: () => {
                                if (this.activeIndex < domains.length) {
                                    this.activeIndex = this.activeIndex + 1
                                    // console.log('onComplete,', this.activeIndex)
                                }
                            }
                        })
                        tl.addLabel(`ìndex${index}`)
                    })
                }
                domainButtons.forEach((domain, index) => {
                    const progress1Domain = 1 / domainButtons.length

                    domain.addEventListener('click', () => {
                        const desiredProgress = progress1Domain * (index + 1)
                        const st = tl.scrollTrigger
                        const targetScroll =
                            st.start + desiredProgress * (st.end - st.start)
                        window.scrollTo({
                            top: targetScroll,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        tl: null,
        activeIndex: 1,
        showDomainBackgrounds: false
    }))

    // console.log('banner code done')
}

const headroom = () => {
    const banner = document.querySelector('.banner')
    let offset = 100
    // if (banner) {
    //     offset =
    //         banner.querySelector('.js-content').offsetHeight -
    //         banner.querySelector('.js-spacer').offsetHeight
    // }

    const headroom = new Headroom(document.querySelector('header'), {
        offset: offset,
        onTop: function () {
            document.querySelector('body').classList.add('headroom--top')
            document.querySelector('body').classList.remove('headroom--not-top')
        },

        onNotTop: function () {
            document.querySelector('body').classList.remove('headroom--top')
            document.querySelector('body').classList.add('headroom--not-top')
        }
    }).init()
}
const bannerEl = document.querySelector('.banner')
if (bannerEl) {
    banner()
}

// App main
const main = async () => {
    headroom()
    // Dit komt overeen met de twig var blockHandle
    // const blocks = []
    const components = [
        'fancybox',
        'swiper',
        'accordionComp',
        'langSwitcher',
        'googleMaps',
        'faq',
        'count',
        'openingHours'
        // 'homeDomains',
        // 'banner'
    ]

    const importPromises = []

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((comp) => {
                    importPromises.push(init(comp))
                })
            }
        )

        importPromises.push(importPromise)
    }

    importPromises.push(
        import('./base/animations.js').then(({ initAnimations }) =>
            initAnimations()
        )
    )

    importPromises.push(
        import('./base/menu.js').then(({ initMenu }) => initMenu())
    )
    // console.log(importPromises)
    return await Promise.all(importPromises)
}

main().then(() => {
    console.log('Vite <3')
    window.Alpine = Alpine

    Alpine.plugin(collapse)

    // Change chevron based on currentColor
    window.addEventListener('load', async (event) => {
        const selectElements = document.querySelectorAll('select')
        selectElements.forEach((select) => {
            let color = getComputedStyle(select).color
            let encodedSVG = encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" style="&#10;    stroke: red;&#10;"><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"/></svg>
  `)
            select.style.backgroundImage = `url("data:image/svg+xml,${encodedSVG}")`
        })
    })

    setTimeout(() => {
        Alpine.start()
    }, 1000)

    const anchor = getAnchor()
    if (anchor) {
        const anchorElement = document.querySelector(`#${anchor}`)
        if (anchorElement) {
            // console.log(anchorElement)
            setTimeout(() => {
                anchorElement.scrollIntoView({ behavior: 'smooth' })
            }, 350)
        }
    }
})

const getAnchor = () => {
    var currentUrl = document.URL,
        urlParts = currentUrl.split('#')

    return urlParts.length > 1 ? urlParts[1] : null
}

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
